@import "~src/assets/styles/settings/colors";
@import "~src/assets/styles/settings/fonts";

// **************************
// Default Helper Tooltip
// **************************
.p-tooltip__helper {
  &.p-tooltip-top .p-tooltip-arrow {
    border-top-color: rgb(0 167 151 / 90%);
  }
  &.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: rgb(0 167 151 / 90%);
  }
  &.p-tooltip-left .p-tooltip-arrow {
    border-left-color: rgb(0 167 151 / 90%);
  }
  &.p-tooltip-right .p-tooltip-arrow {
    border-right-color: rgb(0 167 151 / 90%);
  }

  & .p-tooltip-text {
    font-size: $BaseFontSize;
    line-height: $BaseLineHeight;
    text-align: center;
    padding: 0.5rem;
    background: rgb(0 167 151 / 90%);
    word-break: break-word;
  }
}

// **************************
// Success Helper Tooltip
// **************************
.p-tooltip__success {
  &.p-tooltip-top .p-tooltip-arrow {
    border-top-color: #04b962;
  }
  &.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: #04b962;
  }
  &.p-tooltip-left .p-tooltip-arrow {
    border-left-color: #04b962;
  }
  &.p-tooltip-right .p-tooltip-arrow {
    border-right-color: #04b962;
  }

  & .p-tooltip-text {
    font-size: $BaseFontSize;
    line-height: $BaseLineHeight;
    text-align: center;
    padding: 0.5rem;
    background: #04b962;
    color: #ffffff;
    font-weight: bold;
    word-break: break-word;
  }
}

// **************************
// Warning Helper Tooltip
// **************************
.p-tooltip__warning {
  &.p-tooltip-top .p-tooltip-arrow {
    border-top-color: #ed7d01;
  }
  &.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: #ed7d01;
  }
  &.p-tooltip-left .p-tooltip-arrow {
    border-left-color: #ed7d01;
  }
  &.p-tooltip-right .p-tooltip-arrow {
    border-right-color: #ed7d01;
  }

  & .p-tooltip-text {
    font-size: $BaseFontSize;
    line-height: $BaseLineHeight;
    text-align: center;
    padding: 0.5rem;
    background: #ed7d01;
    color: #ffffff;
    font-weight: bold;
    word-break: break-word;
  }
}

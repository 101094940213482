// **************************
// Headings
// **************************
$H1FontSize: 18px;
$H1LineHeight: 26px;

$H2FontSize: 24px;
$H2LineHeight: 30px;

$H3FontSize: 22px;
$H3LineHeight: 26px;

$H4FontSize: 20px;
$H4LineHeight: 24px;

$H5FontSize: 16px;
$H5LineHeight: 26px;

// **************************
// Base text
// **************************
$BaseFontSize: 12px;
$BaseLineHeight: 16px;

@import "~src/assets/styles/settings/colors";
@import "~src/assets/styles/settings/fonts";

// **************************
// Custom PanelMenu
// **************************
.pickup-points__panel-menu {
  & .p-menuitem-link-active * {
    color: $colorBlue !important;
    font-weight: 600;
  }
  &.p-panelmenu {
    & .p-panelmenu-content {
      background: rgba($colorTurquoise, 0.2);
      border-color: transparent;
      color: $colorBlue;
    }
    & .p-panelmenu-content .p-menuitem .p-menuitem-link {
      padding: 0.75rem 1rem 0.75rem 2.35rem;
      color: $colorWhite;
      box-shadow: none;
    }
    & .p-panelmenu-header > a .p-panelmenu-icon {
      font-size: $BaseFontSize;
      margin-right: 10px;
    }
    & .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon,
    & .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
      color: $colorWhite;
    }
    & .p-panelmenu-panel:first-child .p-panelmenu-header > a {
      border-radius: 0;
      font-weight: 400;
    }
    & .p-panelmenu-header > a {
      background: transparent;
      color: $colorWhite;
      border: none;
    }
    & .p-panelmenu-header > a:focus {
      box-shadow: none;
    }
    & .p-panelmenu-header.p-highlight:not(.p-disabled) > a {
      //color: $colorBlue;
    }
    & .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover,
    & .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover {
      background: transparent;
      border-color: transparent;
      color: $colorBlue;
    }
    & .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover,
    & .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon,
    & .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
      background: transparent;
      border-color: transparent;
      color: $colorBlue;
    }
  }
}

// **************************
// Base colors
// **************************
$colorWhite: #ffffff;
$colorGreen: #86be58;
$colorYellow: #faf7c2;
$colorOrange: #ed7d01;
$colorRed: #ed1c24;
$colorDarkRed: #ba181e;
$colorBlack: #000000;
$colorTurquoise: #50a696;
$colorPurple: #4d5b9c;

// **************************
// Gray colors
// **************************
$colorLightGray: #e8e8e8;
$colorMiddleGray: #8a8b8e;
$colorDarkGray: #acacac;
$colorDarkestGray: #2c2c2c;

// **************************
// Gray colors
// **************************
$colorBlue: #46a4cf;
$colorLightBlue: #06acff;
$colorMiddleBlue: #1b668b;
$colorDarkBlue: #2d3542;
$colorDarkestBlue: #242a33;

// **************************
// Inputs colors
// **************************
$colorInputIcons: $colorDarkGray;
$colorInputIcons_hover: $colorDarkBlue;
$colorInputShadow: #248098;
$colorInputBorder: $colorMiddleGray;
$colorInputBorder_focus: $colorMiddleBlue;
$colorInputBorder_error: $colorRed;
$colorInputBackground_error: #fff1f1;

// **************************
// Buttons colors
// **************************
$colorButtonEvent: #248098;
$colorButtonEvent_hover: $colorMiddleBlue;
$colorButtonGray: #494e57;

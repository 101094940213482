@import "~src/assets/styles/settings/colors";
@import "~src/assets/styles/settings/fonts";

// **************************
// Custom multiSelect
// **************************
.multiSelect__base {
  & {
    width: 100%;
    padding: 5px;
    box-shadow: 0 0 10px -5px $colorDarkGray;
    background: $colorWhite;
    border: 1px solid $colorInputBorder;
  }
  &.p-p-multiselect {
    -webkit-tap-highlight-color: transparent;
    box-shadow: 0 0 10px -5px $colorDarkGray;
  }
  &.error.p-p-multiselect {
    border-color: $colorInputBorder_error;
    background: rgba($colorInputBorder_error, 0.03);
  }
  &.p-p-multiselect:not(.p-disabled).p-focus {
    box-shadow: none;
  }
  &.p-p-multiselect:not(.p-disabled):hover {
    border-color: $colorInputBorder_focus;
  }
  &.p-p-multiselect .p-p-multiselect-panel {
    top: 43px !important;
    max-width: -webkit-fill-available;
  }
  & .p-inputtext {
    font-family: Roboto, Helvetica, sans-serif;
    font-size: $BaseFontSize;
    line-height: $BaseLineHeight;
  }
  & .pi {
    font-size: $BaseFontSize;
    color: $colorMiddleGray;
  }
  & .p-p-multiselect-trigger {
    width: 20px;
  }
  & .p-inputtext {
    border: 1px solid $colorInputBorder;
  }
  & .p-inputtext:enabled:focus {
    box-shadow: 0 0 0 0.2rem rgba($colorButtonEvent, 0.5);
    border-color: $colorButtonEvent;
  }
  & .p-multiselect-token span {
    font-size: $BaseFontSize - 1;
    line-height: $BaseLineHeight;
  }
}

@import "~src/assets/styles/mixins/transition";
@import "~src/assets/styles/mixins/media";
@import "~src/assets/styles/settings/colors";
@import "~src/assets/styles/settings/fonts";

.ui-table {
  & {
    position: relative;
    display: table;
    width: 100%;
  }

  // **************************
  // Thead
  // **************************
  &__head {
    & {
      background: rgba($colorDarkestGray, 0.8);
    }
    & td {
      padding: 10px;
      font-size: $BaseFontSize;
      line-height: $BaseLineHeight;
      font-weight: 500;
      color: $colorWhite;
      border-right: 1px solid rgba($colorMiddleGray, 0.8);
    }
    & td:last-child {
      border-right: none;
    }
  }

  // **************************
  // Tbody
  // **************************
  &__body {
    & {
      //background: rgba($colorDarkestGray, 0.8);
    }
    & td {
      padding: 5px 10px;
      font-size: $BaseFontSize;
      line-height: $BaseLineHeight;
      color: $colorDarkestGray;
      border: 1px solid rgba($colorDarkestGray, 0.8);
      border-right: none;
      border-top: none;
    }
    & td:last-child {
      border-right: 1px solid rgba($colorDarkestGray, 0.8);
    }
  }
}

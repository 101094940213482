@import "~src/assets/styles/mixins/transition";
@import "~src/assets/styles/settings/colors";
@import "~src/assets/styles/mixins/media";

// **************************
// Page base
// **************************
html,
body {
  width: 100%;
  height: 100%;
}
body.p-overflow-hidden .pg-layer {
  filter: blur(5px);
  @include transition-prop(filter, 0.2s, ease-in);
}

// **************************
// Page Layer
// **************************
.pg-layer {
  & {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  &:before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 15%);
    z-index: -1;
  }
}

// **************************
// Page wrapper
// **************************
.pg-wrapper {
  & {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9;
    background: $colorLightGray;
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url("~assets/images/bg/cubes-main.png") center no-repeat;
    background-size: cover;
    opacity: 0.3;
  }
}

// **************************
// Page content
// **************************
.pg-content {
  & {
    position: relative;
    display: flex;
    width: 100%;
    height: calc(100% - 80px);
    @include transition(0.3s, ease);
  }
  &__sidebar {
    position: relative;
    width: 335px;
    height: 100%;
    padding-right: 12.5px;

    @include _lg {
      width: 300px;
    }
    @include _md {
      width: 280px;
    }
    @include _sm {
      width: 100%;
      padding: 0;
    }
  }
  &__wrapper {
    width: calc(100% - 340px);

    @include _lg {
      width: calc(100% - 315px);
    }
    @include _md {
      width: calc(100% - 295px);
    }
    @include _sm {
      width: 100%;
    }
  }
}

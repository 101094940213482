// **************************
// Base listbox
// **************************
.listbox__base {
  &.p-listbox {
    background: rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: #ffffff;
    overflow: hidden;
  }
  &.p-listbox.p-disabled {
    opacity: 1;
  }
  & .p-listbox-list {
    padding: 0;
    box-shadow: 0 2px 10px rgb(0 0 0 / 10%);
  }
  & .p-listbox-list .p-listbox-item {
    background: rgba(0, 0, 0, 0.2);
    color: #ffffff;
    font-size: 12px;
    padding: 0.7rem 1rem;
    box-shadow: none;
    outline: none;
  }
  & .p-listbox-list .p-listbox-item:not(.p-disabled):not(.p-highlight):hover {
    background: rgb(0 167 151 / 50%);
    border-color: rgb(0 167 151 / 50%);
    box-shadow: none;
    color: #ffffff;
  }
  & .p-listbox-list .p-listbox-item.p-highlight,
  & .p-listbox-list .p-listbox-item.p-highlight:hover {
    background: rgb(0 167 151 / 80%);
    border-color: rgb(0 167 151 / 80%);
    box-shadow: none;
    color: #ffffff;
  }
}

@import "~src/assets/styles/settings/colors";
@import "~src/assets/styles/settings/fonts";

// **************************
// Base calendar
// **************************
.calendar__base {
  & {
    position: relative;
    width: 100%;
  }
  &.p-calendar {
    display: flex;
    position: relative;
    width: 100%;
  }
  & .p-inputtext {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    min-height: 38px;
    font-weight: 400;
    line-height: 1.5;
    color: #ffffff;
    background-clip: padding-box;
    border: 0 solid #e5eaef;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  & .p-inputtext:enabled:focus {
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.45);
  }
  &.p-calendar.p-calendar-disabled .p-inputtext {
    opacity: 1;
    background-color: #150e0e73;
  }
}

// **************************
// Filters calendar
// **************************
.calendar__filters {
  & {
    position: relative;
    width: 100%;
  }
  &.p-calendar {
    display: flex;
    position: relative;
    width: 100%;
  }
  & .p-inputtext {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    min-height: 38px;
    font-weight: 400;
    line-height: 1.5;
    color: #ffffff;
    background-clip: padding-box;
    border: 0 solid #e5eaef;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  & .p-inputtext:enabled:focus {
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.45);
  }
}

// **************************
// Base selectButton
// **************************
.selectButton__base {
  & .p-button {
    background: rgba(50, 50, 50, 0.7);
    border-color: rgba(255, 255, 255, 0.12);
    color: #ffffff;
    font-size: 12px;
    padding: 0.2rem 0.8rem;
    box-shadow: none;
  }
  & .p-button:not(.p-disabled):not(.p-highlight):hover {
    background: rgb(0 167 151 / 80%);
    border-color: rgb(0 167 151 / 80%);
  }
  & .p-button.p-highlight,
  & .p-button.p-highlight:hover {
    background: rgb(0 167 151 / 70%);
    border-color: rgb(0 167 151 / 70%);
  }
}
// **************************
// FullWight selectButton
// **************************
.selectButton__full {
  & {
    display: flex;
    width: 100%;
  }
  & .p-component {
    width: 100%;
  }
  & .p-button {
    background: rgba(50, 50, 50, 0.7);
    border-color: rgba(255, 255, 255, 0.12);
    color: #ffffff;
    font-size: 12px;
    padding: 0.4rem 0.8rem;
    box-shadow: none;
  }
  & .p-button:not(.p-disabled):not(.p-highlight):hover {
    background: rgb(0 167 151 / 80%);
    border-color: rgb(0 167 151 / 80%);
    color: #ffffff;
  }
  & .p-button.p-highlight,
  & .p-button.p-highlight:hover {
    background: rgb(0 167 151 / 70%);
    border-color: rgb(0 167 151 / 70%);
  }
}

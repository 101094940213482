@import "~src/assets/styles/mixins/transition";
@import "~src/assets/styles/mixins/media";
@import "~src/assets/styles/settings/colors";
@import "~src/assets/styles/settings/fonts";

.ui-input {
  & {
    position: relative;
    width: 100%;
  }

  // **************************
  // Status Input
  // **************************
  & .error-input {
    input {
      box-shadow: 0 0 0 0.2rem rgba($colorInputBorder_error, 0.6);
    }
    i {
      color: $colorInputBorder_error;
    }
  }
}

@import "~src/assets/styles/settings/colors";

// **************************
// Default autocomplete
// **************************
.p-autocomplete__default {
  &.p-autocomplete {
    width: 100%;
  }
  & .p-inputtext {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 2rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    border: 0 solid #e5eaef;
    background-color: rgba(255, 255, 255, 0.2);
    color: #fff !important;
    background-clip: padding-box;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  & .p-inputtext:focus {
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.45);
  }

  & .p-autocomplete-panel {
    & {
      max-width: 100%;
      margin-top: 4px;
      background-color: #2e3334;
    }
    & span {
      color: #fff !important;
    }
  }

  & .p-autocomplete-items {
    & .p-autocomplete-item.p-highlight,
    & .p-autocomplete-item:hover {
      color: #ffffff;
      background: $colorDarkBlue;
    }
  }
}

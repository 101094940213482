@import "~src/assets/styles/settings/colors";
@import "~src/assets/styles/settings/fonts";
@import "~src/assets/styles/mixins/media";

// **************************
// Default dialog
// **************************
.p-dialog__default {
  & {
    max-width: 700px;
    overflow: hidden;
    border-radius: 5px;
    @include _sm {
      max-height: 100% !important;
      padding: 8px;
    }
  }
  & .p-dialog-header {
    & {
      padding: 0.55rem 1rem;
      background: rgb(50 50 50 / 95%);
    }
    & .p-dialog-title {
      position: relative;
      font-family: "Roboto", sans-serif;
      color: $colorLightGray;
      font-size: $H5FontSize;
      line-height: $H5LineHeight;
      font-weight: 600;
      top: 2px;
    }
  }
  & .p-dialog-header-icons {
    & .pi {
      color: $colorLightGray;
    }
    &:hover .pi {
      color: $colorDarkestGray;
    }
  }
  & .p-dialog-content {
    background: rgb(50 50 50 / 95%);
    padding: 1rem;
    color: $colorLightGray;

    @include _sm {
      padding: 0.5rem 0;
    }
  }
}

// **************************
// Custom Remind Password
// **************************
.p-dialog__confirm {
  & {
    min-width: 400px;
    max-width: 400px;
    overflow: hidden;

    @include _sm {
      min-width: 340px;
      max-width: 340px;
    }
  }
  & .p-dialog-header {
    & {
      padding: 0.55rem 1rem;
      background: $colorLightGray;
    }
    & .p-dialog-title {
      position: relative;
      font-family: "BebasNeue Bold", Helvetica, Arial, sans-serif;
      color: $colorDarkBlue;
      font-size: $H5FontSize;
      line-height: $H5LineHeight;
      top: 2px;
    }
  }
  & .p-dialog-content {
  }
  & .p-confirm-dialog-message {
    font-size: $BaseFontSize;
    line-height: $BaseLineHeight;
    font-weight: 500;
    color: $colorDarkBlue;
  }
  & .p-dialog-content {
    //background: $colorLightGray;
    padding: 1.55rem 1rem;
    color: $colorDarkestGray;
  }
  & .p-dialog-footer {
    padding: 1rem;
    //background: $colorLightGray;
  }
}

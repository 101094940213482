@import "src/assets/styles/settings/colors";
@import "src/assets/styles/settings/fonts";

// **************************
// Custom Splitter
// **************************
.pickup-points__splitter {
  &.p-splitter {
    & {
      position: relative;
      border: none;
      background: transparent;
    }
    & .p-splitter-panel {
      position: relative;
    }
    & .p-splitter-gutter {
      position: relative;
      background: transparent;
      background: $colorDarkestGray;
    }
    & .p-splitter-gutter-handle {
      position: absolute;
      left: 2px;
      background: $colorPurple;
      z-index: 9;
    }
  }
}

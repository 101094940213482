@import "~src/assets/styles/settings/colors";
@import "~src/assets/styles/settings/fonts";

// **************************
// Default datepicker
// **************************
.p-datepicker {
  & span {
    color: #ffffff;
  }
  & table td {
    padding: 0.2rem;
  }
  &.p-datepicker-touch-ui {
    min-width: 300px;
    background: rgb(50 50 50 / 95%);
  }
  &.p-datepicker:not(.p-datepicker-inline) {
    background: rgb(50 50 50 / 95%);
  }
  & .p-datepicker:not(.p-datepicker-inline),
  & .p-datepicker-header {
    background: rgb(50 50 50 / 95%) !important;
  }
  & table td span:not(.p-highlight):not(.p-disabled):hover {
    color: $colorDarkestGray;
  }
  &:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):hover {
    color: $colorDarkestGray !important;
  }
  &:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-highlight):not(.p-disabled):hover {
    color: $colorDarkestGray !important;
  }
}

.p-datepicker-monthpicker {
  & {
    max-width: 245px;
    margin-top: 10px;
  }
  & span {
    font-size: 14px;
  }
}

.p-datepicker-yearpicker {
  & {
    max-width: 245px;
    margin-top: 10px;
  }
  & span {
    font-size: 14px;
  }
}

@import "~src/assets/styles/settings/colors";
@import "~src/assets/styles/settings/fonts";

.inputSwitch__base {
  &.p-inputswitch {
    width: 2.3rem;
    height: 1.4rem;
  }
  &-checked {
  }
  &.p-inputswitch .p-inputswitch-slider {
    background: rgba(255, 255, 255, 0.2);
  }
  &.p-inputswitch:not(.p-disabled).p-focus {
    box-shadow: none;
  }
  &.p-inputswitch-checked .p-inputswitch-slider {
    background-color: #04b962;
    border-color: #04b962;
  }
  &.p-inputswitch-checked:hover .p-inputswitch-slider {
    background: #019e4c !important;
    border-color: #019e4c;
  }
  &.p-inputswitch .p-inputswitch-slider:before {
    width: 1rem;
    height: 1rem;
    left: 0.2rem;
    margin-top: -0.49rem;
  }
  &.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    transform: translateX(0.95rem);
  }
}

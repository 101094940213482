@import "~src/assets/styles/mixins/transition";
@import "~src/assets/styles/mixins/media";
@import "~src/assets/styles/settings/colors";
@import "~src/assets/styles/settings/fonts";

// **************************
// Body
// **************************
body {
  font-family: Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: $colorBlack;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a,
li,
p,
td,
tr,
ul {
  font-size: $BaseFontSize;
  line-height: 1;
}
a,
li,
p,
span,
td,
tr,
ul,
h1,
h2,
h3,
h4,
h5 {
  -webkit-font-smoothing: antialiased;
}
iframe {
  width: 100%;
  height: 100%;
}

// **************************
// Headings
// **************************
h1,
h2,
h3,
h4,
h5 {
  color: $colorDarkestGray;
  @include _sm {
    word-break: break-word;
  }
}

h1 {
  font-size: $H1FontSize;
  line-height: $H1LineHeight;

  @include _sm {
    font-size: $H3FontSize;
    line-height: $H5LineHeight;
  }
}

h2 {
  font-size: $H2FontSize;
  line-height: $H2LineHeight;
}

h3 {
  font-size: $H3FontSize;
  line-height: $H3LineHeight;
}

h4 {
  font-size: $H4FontSize;
  line-height: $H4LineHeight;
}

h5 {
  font-size: $H5FontSize;
  line-height: $H5LineHeight;
  font-weight: 500;
}

// **************************
// Links
// **************************
a {
  font-size: $BaseFontSize;
  line-height: $BaseLineHeight;
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  @include transition(0.15s, linear);
}
a:hover {
  text-decoration: none;
}

// **************************
// Text custom styles
// **************************
.txt-caption {
  color: $colorMiddleBlue;
}
.txt-cta {
  color: rgb(0 167 151 / 90%);
  cursor: pointer;
  outline: none;
  @include transition(0.2s, ease);
}
.txt-cta:hover {
  color: rgb(0 167 151 / 100%);
}

// **************************
// Icons sizes
// **************************
.icon-sm {
  font-size: 14px !important;
}
.icon-md {
  font-size: 16px !important;
}
.icon-lg {
  font-size: 18px !important;
}
.icon-color-success {
  color: $colorGreen;
}
.icon-color-warning {
  color: $colorOrange;
}
.icon-color-error {
  color: $colorRed;
}

@import "~src/assets/styles/settings/colors";

.ui-scroll {
  // **************************
  // Base custom scrollbar
  // **************************
  &-base {
    & {
      overflow-y: auto;
      overflow-x: auto;
      scrollbar-width: thin;
    }
  }

  // **************************
  // Scroll Y without scrollbar
  // **************************
  &-y {
    & {
      overflow: hidden;
      overflow-y: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }

  // **************************
  // Vertical custom scrollbar
  // **************************
  &-vertical {
    & {
      overflow-y: auto !important;
      overflow-x: hidden !important;
      pointer-events: auto !important;
      scrollbar-width: thin;
      scrollbar-color: $colorLightGray transparent;
    }

    &::-webkit-scrollbar {
      width: 3px;
    }
    &.sm-scrollbar::-webkit-scrollbar {
      width: 1px;
    }
    &.no-scrollbar::-webkit-scrollbar {
      display: none;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $colorWhite;
      border-radius: 20px;
    }
  }

  // **************************
  // Horizontal custom scrollbar
  // **************************
  &-horizontal {
    & {
      overflow: hidden;
      overflow-x: auto;
      scrollbar-width: thin;
      scrollbar-width: thin;
      scrollbar-color: $colorLightGray transparent;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar {
      height: 2px;
      background-color: $colorLightGray;
      transition: 0.1s ease-in-out;
      -webkit-transition: 0.1s ease-in-out;
      -webkit-background-clip: text;
    }
    &.sm-scrollbar::-webkit-scrollbar {
      height: 1px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $colorWhite;
      border-radius: 20px;
    }
  }
}

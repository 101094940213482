/*
Examples:

@include _sm {}
@include _md {}
@include _lg {}
@include _hq {}
*/

@mixin _sm {
  @media (min-width: 0) and (max-width: 767px) {
    @content;
  }
}

@mixin _md {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}

@mixin _lg {
  @media (min-width: 1025px) and (max-width: 1440px) {
    @content;
  }
}

@mixin _hq {
  @media (min-width: 1441px) {
    @content;
  }
}
